<template>
    <div style="width: 100%; height: 100%;">
        <!-- <calendar-component /> -->
        <div style="width: 100%; height: 400px; background-color: white;">
            <MnvChart />
        </div>
        <div style="width: 100%; height: calc(100% - 500px); overflow-y: auto; margin-top: 10px;">
            <TableList2
                :columns="test.column"
                :rows="test.rows"
                :title="'분석 데이터 테이블'"
                :keyField="'regDt'"
                :mode="'edit'"
                :controls="controls"
                :toggleFields="['enabled']"
                :groupField="'category'"
                @button-click="handleListButtonClick"
                @toggle-changed="handleItemToggle"
            />
        </div>
    </div>
</template>

<script>
import TableList2 from "@src/views/component/v2.1/ListDetailView/TableList2.vue";
import TableList2DummyData from "@src/views/component/v2.1/ListDetailView/TableList2DummyData.json";
import MnvChart from "./MnvChart.vue";

export default {
    components: {
        TableList2,
        MnvChart,
    },
    data() {
        return {
            controls: [
                { event: "delete", label: "삭제", class: "btn-danger" },
                { event: "status", label: "사용", class: "bg-white text-dark", options: { enabled: "Y" } },
                { event: "status", label: "미사용", class: "bg-white text-dark", options: { enabled: "N" } },
                { event: "regist", label: "추가", class: "btn-primary" },
            ],
            test: TableList2DummyData,
        };
    },

    methods: {
        async handleListButtonClick(event, options) {
            switch (event) {
                case "regist":
                    await this.registNew();
                    break;

                case "delete":
                    await this.deleteChecked();
                    break;

                case "status":
                    await this.changeStatusChecked(options);
                    break;

                default:
                    console.warn("Unhandled Component Event. event:", event, "options:", options);
                    break;
            }
        },
        registNew() {
            console.log("registNew");
        },
        deleteChecked() {
            console.log("deleteChecked");
        },
        changeStatusChecked(options) {
            console.log("changeStatusChecked", options);
        },
        async handleItemToggle(key, field, status) {
            // await this.changeStatus(key, field, status);
            console.log(`key : ${key}, field : ${field}, status : ${status}`);
        },
    },
};
</script>

<style scoped></style>
