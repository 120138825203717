<template>
    <div style="width: 100%; height: 100%;">
        <div ref="mnvChart" style="width: 100%; height: 100%;"></div>
    </div>
</template>

<script>
import * as echarts from "echarts";
import chartData from "./MnvChartDummyData.json";

export default {
    data() {
        return {
            chart: null,
            xAxisLabels: [],
        };
    },
    mounted() {
        this.prepareChart();
    },
    methods: {
        prepareChart() {
            this.renderChart();
        },
        renderChart() {
            this.chart = echarts.init(this.$refs.mnvChart);
            const lastIndex = chartData.findLastIndex((item) => !item.l);

            const option = {
                title: {
                    text: "M&V Chart",
                    subtext: "Example m&v chart report",
                    left: "center",
                },
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        type: "shadow", // cross
                        animation: false,
                    },
                },
                grid: {
                    left: "3%",
                    right: "4%",
                    bottom: "3%",
                    containLabel: true,
                },
                xAxis: {
                    type: "category",
                    data: chartData.map((item) => item.date),
                    boundaryGap: true,
                },
                yAxis: {
                    axisLabel: {
                        formatter: function(val) {
                            return val;
                        },
                    },
                    splitNumber: 3,
                },
                series: [
                    {
                        name: "L",
                        type: "line",
                        data: chartData.map((item) => item.l),
                        lineStyle: { opacity: 0 },
                        smooth: 0.4,
                        stack: "confidence-band",
                        symbol: "none",
                    },
                    {
                        name: "U",
                        type: "line",
                        data: chartData.map((item) => item.u),
                        lineStyle: { opacity: 0 },
                        smooth: 0.4,
                        areaStyle: { color: "#ccc" },
                        stack: "confidence-band",
                        symbol: "none",
                    },
                    {
                        name: "Target Data",
                        type: "line",
                        data: chartData.map((item) => item.value),
                        smooth: 0.4,
                        symbolSize: 3,
                        markLine: {
                            symbol: "none",
                            data: [
                                {
                                    name: "하한선",
                                    label: {
                                        formatter: function(params) {
                                            return `${params.data.name} (${params.data.value})`;
                                        },
                                        position: "insideEndTop",
                                    },
                                    lineStyle: {
                                        type: "dashed",
                                        color: "red",
                                    },
                                    yAxis: 17,
                                },
                                {
                                    name: "상한선",
                                    label: {
                                        formatter: function(params) {
                                            return `${params.data.name} (${params.data.value})`;
                                        },
                                        position: "insideEndTop",
                                    },
                                    lineStyle: {
                                        type: "dashed",
                                        color: "red",
                                    },
                                    yAxis: 58,
                                },
                                {
                                    name: "평균선",
                                    label: {
                                        formatter: function(params) {
                                            return `${params.data.name} (${params.data.value})`;
                                        },
                                        position: "insideEndTop",
                                    },
                                    lineStyle: {
                                        type: "dashed",
                                        color: "red",
                                    },
                                    yAxis: 33,
                                },
                                {
                                    label: {
                                        formatter: "구분선",
                                        position: "insideEndTop",
                                    },
                                    lineStyle: {
                                        type: "dashed",
                                        color: "#333",
                                    },
                                    xAxis: chartData[lastIndex].date,
                                },
                            ],
                        },
                        markArea: {
                            label: {
                                show: true,
                                fontSize: 12,
                            },
                            data: [
                                [
                                    {
                                        name: "base-line",
                                        itemStyle: {
                                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                { offset: 0, color: "#BBDEFB" },
                                                { offset: 1, color: "#BBDEFB" + "00" },
                                            ]),
                                        },
                                        xAxis: chartData[0].date,
                                    }, // 시작점
                                    { xAxis: chartData[lastIndex].date }, // 끝점
                                ],
                                [
                                    {
                                        name: "report-line",
                                        itemStyle: {
                                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                                { offset: 0, color: "#C8E6C9" },
                                                { offset: 1, color: "#C8E6C9" + "00" },
                                            ]),
                                        },
                                        xAxis: chartData[lastIndex + 1].date,
                                    }, // 시작점
                                    { xAxis: chartData[chartData.length - 1].date }, // 끝점
                                ],
                            ],
                        },
                    },
                ],
                visualMap: {
                    show: false,
                    dimension: 0,
                    pieces: [
                        {
                            gt: 0,
                            lte: lastIndex,
                            color: "blue",
                        },
                        {
                            gt: lastIndex + 1,
                            lte: chartData.length - 1,
                            color: "green",
                        },
                    ],
                },
            };

            this.chart.setOption(option);

            window.addEventListener("resize", () => {
                this.chart.resize();
            });
        },
    },
};
</script>
